import session from './session';

let baseUrl = ''; // for distribution with Django backend
if (process.env.NODE_ENV === 'development') {
  baseUrl = 'http://127.0.0.1:8000';
}

export default {
  loadClients(firm_id) {
    return session.get(`${baseUrl}/api/firms/${firm_id}/clients/`);
  },
  loadMe() {
    return session.get(`${baseUrl}/api/me/`);
  },
  loadClient(client_id) {
    return session.get(`${baseUrl}/api/clients/${client_id}/`);
  },
  loadClientDocs(client_id) {
    return session.get(`${baseUrl}/api/clients/${client_id}/documents/`);
  },
  loadClientOverrides(client_id) {
    return session.get(`${baseUrl}/api/clients/${client_id}/overrides/`);
  },
  updateOverride(override) {
    return session.put(`${baseUrl}/api/overrides/${override.id}/`, override);
  },
  addOverride(override) {
    return session.post(`${baseUrl}/api/overrides/`, override);
  },
  deleteOverride(id) {
    return session.delete(`${baseUrl}/api/overrides/${id}/`);
  },
  updateScores(id) {
    return session.post(`${baseUrl}/api/score/`, { id });
  },
};
