<template>
  <v-container fluid>
    <v-data-table
      v-if="clients"
      :headers="headers"
      :items="clients"
      :items-per-page="25"
      :footer-props="{
        showFirstLastPage: true,
        itemsPerPageOptions: [10, 25, -1],
      }"
      class="elevation-1"
      no-data-text="No clients available"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="gotoClient(item)">
          mdi-view-list
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Clients',
  data() {
    return {
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Postcode', value: 'postcode' },
        { text: 'SIC', value: 'sic' },
        { text: 'Scores', value: 'score' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  computed: {
    isAuthorised() {
      // return this.$store.getters.isAuthorised
      return this.$store.getters['auth/isAuthenticated'];
    },
    ...mapGetters('auth', ['isAuthenticated']),
    ...mapGetters('clients', ['clients', 'me', 'error', 'loading']),
  },
  watch: {
    me(newValue, oldValue) {
      console.log(`Updating me from ${oldValue} to ${newValue}`);
      this.$store.dispatch('clients/loadClients');
    },
  },
  methods: {
    gotoClient(item) {
      console.log('gotoClient', item.id);
      this.$router.push('/client/' + item.id);
    },
  },
  mounted() {
    console.log('authorised', this.isAuthenticated);
    if (this.isAuthenticated) {
      if (this.me.firm) {
        console.log(`ok`);
        this.$store.dispatch('clients/loadClients');
      } else {
        this.$store.dispatch('clients/loadMe');
      }

      console.log('Yay! Authorised');
    } else {
      console.log('Bah! Not Authorised');
      this.$router.push({ name: 'SignIn' });
    }
  },
};
</script>
