import assess from '../api/clients';

import {
  LOADING_BEGIN,
  LOADING_FAILURE,
  LOADING_SUCCESS,
  SET_CLIENTS,
  SET_CLIENT,
  SET_CLIENT_DOCS,
  SET_CLIENT_OVERRIDES,
  SET_ME,
} from './types';

const initialState = {
  me: {},
  clients: null,
  client: null,
  client_docs: null,
  client_overrides: null,
  loading: false,
  error: false,
};

const getters = {
  me: (state) => state.me,
  clients: (state) => state.clients,
  client: (state) => state.client,
  client_docs: (state) => state.client_docs,
  client_overrides: (state) => state.client_overrides,
  loading: (state) => state.loading,
  error: (state) => state.error,
};

const actions = {
  loadClients({ commit, state }) {
    commit(LOADING_BEGIN);
    commit(SET_CLIENTS, null);
    // console.log('clients');
    return assess
      .loadClients(state.me.firm)
      .then(({ data }) => commit(SET_CLIENTS, data))
      .then(() => commit(LOADING_SUCCESS))
      .catch(() => commit(LOADING_FAILURE));
  },
  loadClient({ commit }, { id }) {
    commit(LOADING_BEGIN);
    commit(SET_CLIENT, null);
    // console.log('client');
    return assess
      .loadClient(id)
      .then(({ data }) => commit(SET_CLIENT, data))
      .then(() => commit(LOADING_SUCCESS))
      .catch(() => commit(LOADING_FAILURE));
  },
  loadClientDocs({ commit }, { id }) {
    commit(LOADING_BEGIN);
    commit(SET_CLIENT_DOCS, null);
    // console.log('client');
    return assess
      .loadClientDocs(id)
      .then(({ data }) => commit(SET_CLIENT_DOCS, data))
      .then(() => commit(LOADING_SUCCESS))
      .catch(() => commit(LOADING_FAILURE));
  },
  loadClientOverrides({ commit }, { id }) {
    commit(LOADING_BEGIN);
    commit(SET_CLIENT_OVERRIDES, null);
    // console.log('client');
    return assess
      .loadClientOverrides(id)
      .then(({ data }) => commit(SET_CLIENT_OVERRIDES, data))
      .then(() => commit(LOADING_SUCCESS))
      .catch(() => commit(LOADING_FAILURE));
  },
  loadMe({ commit }) {
    commit(LOADING_BEGIN);
    // console.log('me');
    return assess
      .loadMe()
      .then(({ data }) => commit(SET_ME, data))
      .then(() => commit(LOADING_SUCCESS))
      .catch(() => commit(LOADING_FAILURE));
  },
  updateOverrides({ commit }, { id, overrides }) {
    commit(LOADING_BEGIN);
    console.log('updateOverrides', id, overrides);
    return assess
      .loadClient(id)
      .then(({ data }) => commit(SET_CLIENT, data))
      .then(() => commit(LOADING_SUCCESS))
      .catch(() => commit(LOADING_FAILURE));
  },
  updateOverride({ commit }, { override }) {
    commit(LOADING_BEGIN);
    console.log('updateOverride', override.id);
    return assess
      .updateOverride(override)
      .then(() => commit(LOADING_SUCCESS))
      .catch(() => commit(LOADING_FAILURE));
  },
  addOverride({ commit }, { override }) {
    commit(LOADING_BEGIN);
    console.log('addOverride', override.id);
    return assess
      .addOverride(override)
      .then(() => commit(LOADING_SUCCESS))
      .catch(() => commit(LOADING_FAILURE));
  },
  deleteOverride({ commit }, { id }) {
    commit(LOADING_BEGIN);
    console.log('deleteOverride', id);
    return assess
      .deleteOverride(id)
      .then(() => commit(LOADING_SUCCESS))
      .catch(() => commit(LOADING_FAILURE));
  },
  updateScores({ commit }, { id }) {
    commit(LOADING_BEGIN);
    console.log('updateScores', id);
    return assess
      .updateScores(id)
      .then(() => commit(LOADING_SUCCESS))
      .catch(() => commit(LOADING_FAILURE));
  },
};

const mutations = {
  [LOADING_BEGIN](state) {
    state.loading = true;
    state.error = false;
  },
  [LOADING_FAILURE](state) {
    state.loading = false;
    state.error = true;
  },
  [LOADING_SUCCESS](state) {
    state.loading = false;
    state.error = false;
  },
  [SET_CLIENTS](state, clients) {
    // console.log('SET_CLIENTS', clients);
    state.clients = clients;
  },
  [SET_CLIENT](state, client) {
    // console.log('SET_CLIENTS', clients);
    state.client = client;
  },
  [SET_CLIENT_DOCS](state, client_docs) {
    // console.log('SET_CLIENT_DOCS', docs);
    state.client_docs = client_docs;
  },
  [SET_CLIENT_OVERRIDES](state, overrides) {
    // console.log('SET_CLIENT_OVERRIDES', overrides);
    state.client_overrides = overrides;
  },
  [SET_ME](state, me) {
    // console.log('SET_ME', me);
    state.me = me;
  },
};
export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
