<template>
  <div v-if="client">
    <v-dialog v-model="overrideDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Overrides</span>
        </v-card-title>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr v-for="(item, idx) in localOverrides" :key="idx">
                  <td>
                    <b>{{ item.key }}</b>
                  </td>
                  <td width="150">
                    <v-text-field v-model="item.value"></v-text-field>
                  </td>
                  <td>
                    <v-btn icon @click="deleteLocalOverride(idx)"
                      ><v-icon small>mdi-delete</v-icon></v-btn
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on" icon>
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in addItems"
                :key="index"
                @click="addLocalItem(item)"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="overrideDialog = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="saveOverrides">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card class="mt-4">
      <v-card-title>{{ client.name }}</v-card-title>
      <v-row
        ><v-col cols="12" sm="6">
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>
                    {{ client.name }}
                  </td>
                </tr>
                <tr>
                  <td>Postcode</td>
                  <td>{{ client.postcode }}</td>
                </tr>
                <tr>
                  <td>SIC</td>
                  <td>{{ client.sic }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-col cols="12" sm="6">
          <v-simple-table v-if="essentials">
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>Turnover</td>
                  <td>{{ essentials.acc['core_TurnoverRevenue'] }}</td>
                </tr>
                <tr>
                  <td>Profit</td>
                  <td>{{ essentials.acc['core_ProfitLoss'] }}</td>
                </tr>
                <tr>
                  <td>TaxPayable</td>
                  <td>{{ essentials.CT600['TaxPayable'] }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card>
    <v-card v-if="scores">
      <v-card-title
        >Scores<v-btn
          color="primary dark darken-1"
          class="ml-4"
          @click="recalcScores"
          >ReCalc</v-btn
        ></v-card-title
      >
      <v-row>
        <v-col cols="12">
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr v-for="(score, idx) in scores" :key="idx">
                  <td>{{ score.scorer }}</td>
                  <td>
                    {{ score.score }}
                  </td>
                  <td>
                    {{ score.reason }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card>
    <v-card v-if="essentials">
      <v-card-title>Essentials</v-card-title>
      <v-row>
        <v-col cols="12" sm="6">
          <v-subheader>Companies House</v-subheader>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr v-for="(item, idx) in entries.CH" :key="idx">
                  <td>
                    <b>{{ item[0] }}</b>
                  </td>
                  <td width="150">
                    {{ item[1] }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-subheader
            >Overrides<v-btn icon @click.stop="openDialog"
              ><v-icon small>mdi-pencil</v-icon></v-btn
            ></v-subheader
          >
          <v-simple-table v-if="client_overrides">
            <template v-slot:default>
              <tbody>
                <tr v-for="(item, idx) in client_overrides" :key="idx">
                  <td>
                    <b>{{ item.key }}</b>
                  </td>
                  <td width="150">
                    {{ item.value }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-col cols="12" sm="6">
          <v-subheader>Accounts</v-subheader>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr v-for="(item, idx) in entries.acc" :key="idx">
                  <td>
                    <b>{{ item[0] }}</b>
                  </td>
                  <td width="150">
                    {{ item[1] }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-subheader>CT600</v-subheader>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr v-for="(item, idx) in entries.CT600" :key="idx">
                  <td>
                    <b>{{ item[0] }}</b>
                  </td>
                  <td width="150">
                    {{ item[1] }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: ['id'],
  data() {
    return {
      headers: [
        { text: 'Name', value: 'key' },
        { text: 'Value', value: 'value', width: 100 },
      ],
      addItems: [
        { title: 'acc:core_OtherRepairsMaintenanceExpense' },
        { title: 'acc:core_RepairsMaintenanceExpenseProperty-related' },
        { title: 'acc:core_TotalOperatingLeasePayments' },
        { title: 'acc:core_TurnoverRevenue' },
        { title: 'CH:status' },
        { title: 'CH:sic' },
        { title: 'CH:type' },
        { title: 'CT600:RandDEnhancedExpenditure' },
        { title: 'CT600:RandDEnhancedExpenditure' },
        { title: 'espacenet:patents' },
        { title: 'landreg:properties' },
        { title: 'landreg:property_value' },
      ],
      overrideDialog: false,
      addDialog: false,
      localOverrides: null,
      reloadTimer: null,
      overrideReloadTimer: null,
    };
  },
  name: 'Client',
  computed: {
    isAuthorised() {
      // return this.$store.getters.isAuthorised
      return this.$store.getters['auth/isAuthenticated'];
    },
    ...mapGetters('auth', ['isAuthenticated']),
    ...mapGetters('clients', [
      'client',
      'client_docs',
      'client_overrides',
      'error',
      'loading',
    ]),
    scores() {
      if (!this.client_docs) {
        return [];
      }
      const result = this.client_docs.filter((doc) => doc.name === 'scores');
      if (result.length < 1) {
        return [];
      }

      return result[0].data;
    },
    essentials() {
      if (!this.client_docs) {
        return null;
      }
      const result = this.client_docs.filter((doc) => doc.name === 'essential');
      if (result.length < 1) {
        return null;
      }

      return result[0].data;
    },
    entries() {
      const essentials = this.essentials;
      if (!essentials) {
        return null;
      }
      return {
        CH: Object.entries(essentials.CH),
        acc: Object.entries(essentials.acc),
        CT600: Object.entries(essentials.CT600),
      };
    },
  },
  watch: {
    $route: 'reMount',
  },
  methods: {
    reMount() {
      console.log('reMount');
      let id = this.id;
      this.$store.dispatch('clients/loadClient', { id });
      this.$store.dispatch('clients/loadClientDocs', { id });
      this.$store.dispatch('clients/loadClientOverrides', { id });
    },
    openDialog() {
      this.localOverrides = this.client_overrides.map((x) => {
        return {
          id: x.id,
          client: x.client,
          key: x.key,
          value: x.value,
        };
      });
      this.overrideDialog = true;
    },
    saveOverrides() {
      let id = this.id;
      console.log('saveOverrides', id, this.localOverrides);
      const clientOverrideDict = this.client_overrides.reduce(
        (a, x) => ({ ...a, [x.key]: x.value.toString() }),
        {}
      );
      const localOverrides = this.localOverrides.reduce(
        (a, x) => ({ ...a, [x.key]: x.value.toString() }),
        {}
      );
      //   let overrides = [];
      const self = this;
      this.localOverrides.forEach(function (override) {
        const val = clientOverrideDict[override.key];
        if (val != override.value) {
          console.log(override);
          if ('id' in override) {
            self.$store.dispatch('clients/updateOverride', { override });
          } else {
            self.$store.dispatch('clients/addOverride', { override });
          }
          //   overrides.push(override);
        }
      });
      this.client_overrides.forEach(function (override) {
        if (!(override.key in localOverrides)) {
          let id = override.id;
          self.$store.dispatch('clients/deleteOverride', { id });
        }
      });
      this.triggerOverrideReloadTimer();

      //   console.log(overrides);

      //   const overrides = this.entries.overrides;
      //   this.$store.dispatch('clients/updateOverrides', { id, overrides });
      this.overrideDialog = false;
    },
    deleteLocalOverride(idx) {
      console.log('deleteLocalOverride', idx);
      this.localOverrides.splice(idx, 1);
    },
    addLocalItem(item) {
      console.log('addLocalItem', item.title);
      this.localOverrides.push({
        client: this.id,
        key: item.title,
        value: '0',
      });
    },
    recalcScores() {
      console.log('recalcScores');
      let id = this.id;
      this.$store.dispatch('clients/updateScores', { id });
      this.triggerReloadTimer();
    },
    triggerOverrideReloadTimer() {
      this.overrideReloadTimer = setTimeout(() => {
        let id = this.id;
        this.$store.dispatch('clients/loadClientOverrides', { id });
      }, 1500);
    },
    triggerReloadTimer() {
      this.reloadTimer = setTimeout(this.reMount, 1500);
    },
    cancelAutoUpdate() {
      if (this.reloadTimer) {
        clearTimeout(this.reloadTimer);
      }
      if (this.overrideReloadTimer) {
        clearTimeout(this.overrideReloadTimer);
      }
    },
  },
  mounted() {
    console.log('authorised', this.isAuthorised);
    if (this.isAuthorised) {
      this.reMount();
      //   let id = this.id;
      //   this.$store.dispatch('clients/loadClient', { id });
      //   this.$store.dispatch('clients/loadClientDocs', { id });
      //   this.$store.dispatch('clients/loadClientOverrides', { id });
    } else {
      this.$router.push('/login');
    }
  },
  beforeDestroy() {
    this.cancelAutoUpdate();
  },
};
</script>
